<template>
  <vue-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    :paginate-elements-by-height="1100"
    :filename="fileName"
    :pdf-quality="2"
    :manual-pagination="false"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="800px"
    ref="html2Pdf"
    @beforeDownload="beforePDFDownload($event)"
  >
    <v-app slot="pdf-content">
      <v-main class="pdf-contents">
        <section class="pdf-item pdf-item-score-overview">
          <v-container>
            <v-row>
              <v-col
                v-for="(scoreOverviewItem, key) in scoreOverviewItems"
                :key="key"
                cols="6"
                class="pt-0 mb-2 col px-2"
              >
                <v-card class="mx-auto" style="height: 100%">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6">
                        {{ scoreOverviewItem.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="scoreOverviewItem.subtitle">
                        <small>{{ scoreOverviewItem.subtitle }}</small>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-text class="pt-0">
                    <v-row align="center">
                      <v-col class="text-h4 font-weight-bold">
                        {{ scoreOverviewItem.score }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item pdf-item-charts">
          <v-container class="container-pb-0">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title style="font-size: 17px;" class="pa-1 pl-2 bg-steel-blue white--text text-uppercase">
                    Score Trend
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div class="score-trend-chart-container">
                      <canvas class="score-trend-chart"></canvas>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-card>
                  <v-card-title style="font-size: 17px;" class="pa-1 pl-2 bg-steel-blue white--text text-uppercase"
                    >CDC Trend</v-card-title
                  >
                  <v-divider></v-divider>
                  <v-card-text>
                    <div>
                      <line-chart
                        :data="cdcTrend.data"
                        :colors="['#4CAEE3']"
                        :curve="false"
                        :legend="false"
                        :discrete="true"
                        :library="cdcTrend.options"
                        :max="cdcTrend.maxScore"
                      ></line-chart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item pdf-item-charts">
          <v-container class="container-pb-0">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title style="font-size: 17px;" class="pa-1 pl-2 bg-steel-blue white--text text-uppercase">
                    Respondent Trend
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div class="respondent-trend-chart-container">
                      <canvas class="respondent-trend-chart"></canvas>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-card-title style="font-size: 17px;" class="pa-1 pl-2 bg-steel-blue white--text text-uppercase"
                    >Regional Score</v-card-title
                  >
                  <v-divider></v-divider>
                  <v-card-text>
                    <div>
                      <philippine-map-chart
                        map-color="blue"
                        :items="regionalScoreItems"
                        show-legends
                      ></philippine-map-chart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>

        <div class="html2pdf__page-break" />

        <section class="pdf-item pdf-item-charts">
          <v-container class="container-pb-0">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title style="font-size: 17px;" class="pa-1 pl-2 bg-steel-blue white--text text-uppercase">
                    Factor Score
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <div class="factor-score-chart-container">
                      <canvas class="factor-score-chart"></canvas>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-card-title style="font-size: 17px;" class="pa-1 pl-2 bg-steel-blue white--text text-uppercase"
                    >Attributes</v-card-title
                  >
                  <v-divider></v-divider>
                  <v-card-text>
                    <div>
                      <bar-chart
                        :data="topBottomAttributes.data"
                        :colors="['#4CAEE3', '#D8392B']"
                        :stacked="true"
                        :library="topBottomAttributes.options"
                        :max="topBottomAttributes.maxScore"
                      ></bar-chart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-main>
    </v-app>
  </vue-html2pdf>
</template>

<script>
import Vue from "vue";
import PhilippineMapChart from "./PhilippineMapChart.vue";
import CircleValue from "..//partials/CircleValue.vue";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import VueHtml2pdf from "vue-html2pdf";

Chart.plugins.register(ChartDataLabels);
Vue.use(Chartkick.use(Chart));

export default {
  components: {
    PhilippineMapChart,
    CircleValue,
    VueHtml2pdf,
  },
  props: {
    scoreOverviewItems: {
      type: Array,
      default: [],
    },
    cdcTrend: {
      type: Object,
      default: {},
    },
    regionalScoreItems: {
      type: Array,
      default: [],
    },
    topBottomAttributes: {
      type: Object,
      default: {},
    },

    fileName: {
      type: String,
      default: "charts",
    },
  },
  data: () => ({}),
  computed: {},
  methods: {
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
    beforePDFDownload({ html2pdf, options, pdfContent }) {
      this.$emit("beforePDFDownload", { html2pdf, options, pdfContent });
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.html2pdf__page-break {
  margin: 0 !important;
}

.pdf-contents {
  margin: 0px !important;
  padding: 0px !important;
  height: 100% !important;
  width: 100% !important;

  .pdf-item-score-overview {
    padding-top: 20px !important;
  }
  .pdf-item-charts {
    padding-top: 40px !important;
  }
  .container-pb-0 {
    padding-bottom: 0px !important;
  }
}
</style>
