import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
	methods: {
		MIXINS_setDummyTopBottomAttributes() {
			this.topBottomAttributes.data = [
				{
					name: "Top",
					data: {
						"How would you rate 1 hour Honda Blitz ...": Math.floor(
							Math.random() * 10
						),
						"How was your booking arrangement?": Math.floor(Math.random() * 10),
						"How satisfied are you with your Service ...": Math.floor(
							Math.random() * 10
						),
					},
				},
				{
					name: "Bottom",
					data: {
						"How was your shortness of waiting time to be ...": Math.floor(
							Math.random() * 10
						),
						"Please rate the implemented preventive ...": Math.floor(
							Math.random() * 10
						),
						"How would you rate the charges for ...": Math.floor(
							Math.random() * 10
						),
					},
				},
			];
		},
		MIXINS_setDummyCdcTrend() {
			const data = {};
			const months = [...Array(12).keys()].map((key) =>
				new Date(0, key).toLocaleString("en", { month: "short" })
			);

			months.forEach((item) => {
				data[item] = Math.floor(Math.random() * 200);
			});

			this.cdcTrend.data = data;
		},
		MIXINS_setDummyScoreOverview() {
			const csiScore = {
        title: 'CSI Score',
        subtitle: 'Total CSI Score',
        score: 0
      }

      const respondents = {
        title: 'Respondents',
        subtitle: 'Total number of respondents',
        score: 0
      }

      const overallStatisfactionScore = {
        title: 'Overall Satisfaction Score',
        subtitle: null,
        score: 0
      }

      const npsScore = {
        title: 'NPS Score',
        subtitle: 'Total NPS Score',
        score: 0
      }

      const cdcCount = {
        title: 'CDC Count',
        subtitle: 'Total number of CDC',
        score: 0
      }
			this.scoreOverviewItems = [
				csiScore,
				respondents,
				overallStatisfactionScore,
				npsScore,
				cdcCount,
			]
		},
		MIXINS_setDummyRegionalScore() {
			this.regionalScoreItems = [
				{
					value: "88.88%",
					name: "Metro Manila",
					coordinateX: 105,
					coordinateY: 100,
					bgColor: "#f44336 ",
					// textResultClass: 1,
				},
				{
					value: "88.88%",
					name: "Provincial",
					coordinateX: 180,
					coordinateY: 220,
					bgColor: "#009688",
					textResultClass: "black--text font-weight-bold",
				},
			];
		},
		MIXINS_setDummyFactorScore() {
			const factorScores = [
				"Service Initiation",
				"Service Advisor",
				"Service Facility",
				"Service Quality",
				"Vehicle Pick-up",
			];

			const actualTargetData = {
				targetData: {
					label: "Target",
					data: [],
					type: "line",
					backgroundColor: "red",
					borderColor: "#D8392B",
					fill: false,
					order: 1,
					pointStyle: "rectRot",
					radius: 5,
					lineTension: 0,
				},
				actualData: {
					label: "Actual",
					data: [],
					backgroundColor: "#4CAEE3",
					order: 2,
				},
			};

			const max = 1000;

			for (let index = 0; index < factorScores.length; index++) {
				actualTargetData.targetData.data.push(Math.floor(Math.random() * max));
				actualTargetData.actualData.data.push(Math.floor(Math.random() * max));
			}

			this.factorScore = {
				plugins: [ChartDataLabels],
				type: "bar",
				data: {
					datasets: [actualTargetData.targetData, actualTargetData.actualData],
					labels: factorScores,
				},
				options: {
					plugins: {
						datalabels: {
							anchor: "end",
							align: "end",
							formatter: function (value, context) {
								if (context.dataset.label == "Actual") {
									return Math.round(value * 100) / 100;
								} else {
									return null;
								}
							},
						},
					},
				},
			};

			this.setupFactorScoreChart();
		},
		MIXINS_setDummyScoreTrend() {
			const months = [...Array(12).keys()].map((key) =>
				new Date(0, key).toLocaleString("en", { month: "short" })
			);
			const scoreTrend = {
				min: 0, // dummy
				max: 1000, // dummy
				target: 500, // need to be defined
			};

			const actualTargetData = {
				targetData: {
					label: "Target",
					data: [],
					type: "line",
					backgroundColor: "red",
					borderColor: "#D8392B",
					fill: false,
					order: 1,
					pointStyle: "rectRot",
					radius: 5,
					lineTension: 0,
				},
				actualData: {
					label: "Actual",
					data: [],
					backgroundColor: "#4CAEE3",
					order: 2,
				},
			};

			months.forEach((item) => {
				actualTargetData.targetData.data.push(scoreTrend.target);
				actualTargetData.actualData.data.push(
					Math.floor(
						Math.random() * (scoreTrend.max - scoreTrend.min) + scoreTrend.min
					)
				);
			});

			this.scoreTrend = {
				plugins: [ChartDataLabels],
				type: "bar",
				data: {
					datasets: [actualTargetData.targetData, actualTargetData.actualData],
					labels: months,
				},
				options: {
					responsive: true,
					maintainAspectRatio: true,
					aspectRatio: 1.5,
					plugins: {
						datalabels: {
							anchor: "end",
							align: "end",
							formatter: function (value, context) {
								if (context.dataset.label == "Actual") {
									return Math.round(value * 100) / 100;
								} else {
									return null;
								}
							},
						},
					},
				},
			};

			this.setupScoreTrendChart();
		},
		MIXINS_setDummyRespondentTrend() {
			let labels = [];
			const months = [...Array(12).keys()].map((key) =>
				new Date(0, key).toLocaleString("en", { month: "short" })
			);

			const actualTargetData = {
				targetData: {
					label: "Ratio",
					data: [],
					type: "line",
					backgroundColor: "red",
					borderColor: "#D8392B",
					fill: false,
					order: 1,
					pointStyle: "rectRot",
					radius: 5,
					lineTension: 0,
					datalabels: {
						labels: {
							title: null,
						},
					},
				},
				actualData: {
					label: "Intake",
					data: [],
					backgroundColor: "#4CAEE3",
					order: 2,
				},
			};

			const numberOfQuarters = 11;

			const max = 11;

			months.forEach((item) => {
				actualTargetData.targetData.data.push(Math.random() * 7);
				actualTargetData.actualData.data.push(Math.random() * max);
				labels.push(item);
			});

			this.respondentTrend = {
				plugins: [ChartDataLabels],
				type: "bar",
				data: {
					datasets: [actualTargetData.targetData, actualTargetData.actualData],
					labels,
				},
				options: {
					plugins: {
						datalabels: {
							anchor: "end",
							align: "end",
							formatter: function (value, context) {
								if (context.dataset.label == "Ratio") {
									return Math.round(value * 100) / 100 + '%';
								} else {
									return null;
								}
							},
						},
					},
				},
			};

			this.setupRespondentTrendChart();
		},
		MIXINS_setDummyData() {
			this.MIXINS_setDummyRespondentTrend();
			this.MIXINS_setDummyTopBottomAttributes();
			this.MIXINS_setDummyScoreTrend();
			this.MIXINS_setDummyCdcTrend();
			this.MIXINS_setDummyScoreOverview();
			this.MIXINS_setDummyRegionalScore();
			this.MIXINS_setDummyFactorScore();
		},
	}
};
