import { ADDED_TO_CHART_SMALL_SCORING, ADDED_TO_CHART_LARGE_SCORING } from './chartConfig'

export const scoreTrendOptions = {
	plugins: null,
	type: "bar",
	data: {
		datasets: [],
		labels: [],
	},
	options: {
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 1.5,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
				}
			}]
		},
		plugins: {
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: function (value, context) {
					if (context.dataset.label == "Actual") {
						return Math.round(value * 100) / 100;
					} else {
						return null;
					}
				},
			},
		},
	},
}

export const setupScoreTrend = ({ data = [], targetScores = [] }) => {
	let periods = data

	const scoreTrend = {
		target: targetScores.dealer_target_score || targetScores.default_target_score
	};

	const actualTargetData = {
		targetData: {
			label: "Target",
			data: [],
			type: "line",
			backgroundColor: "red",
			borderColor: "#D8392B",
			fill: false,
			order: 1,
			pointStyle: "rectRot",
			radius: 5,
			lineTension: 0,
		},
		actualData: {
			label: "Actual",
			data: [],
			backgroundColor: "#4CAEE3",
			order: 2,
		},
	};
	
	let months = []
	let tempHighestScore = 0  //used for the max value of the chart
	periods.forEach((item) => {
		let higherScore = scoreTrend.target >= item.csi_score ? scoreTrend.target : item.csi_score

		tempHighestScore = tempHighestScore >= higherScore ? tempHighestScore : higherScore
		actualTargetData.targetData.data.push(scoreTrend.target);
		actualTargetData.actualData.data.push(item.csi_score);
		months.push(item.month)
	});

	tempHighestScore = Number(tempHighestScore)

	return {
		datasets: [actualTargetData.targetData, actualTargetData.actualData],
		labels: months,
		maxScore: tempHighestScore
	}
}

export const setScoreTrendOptions = ({ plugins = null, datasets = [], labels = [], max = null }) => {
	scoreTrendOptions.plugins = plugins
	scoreTrendOptions.data.datasets = datasets
	scoreTrendOptions.data.labels = labels
	scoreTrendOptions.options.scales.yAxes[0].ticks.max = max

	return scoreTrendOptions;
}

export const setupScoreTrendChart = ({ data = [], targetScores = [], plugins = null }) => {
	let { datasets, labels, maxScore } = setupScoreTrend({ data, targetScores });
	const newMaxScore = Math.ceil((maxScore + (maxScore * ADDED_TO_CHART_SMALL_SCORING)) / ADDED_TO_CHART_LARGE_SCORING) * ADDED_TO_CHART_LARGE_SCORING
	return setScoreTrendOptions({
		plugins,
		datasets,
		labels,
		max: newMaxScore <= 0 ? 1 : newMaxScore
	})
}
