<template>
    <div>
        <!-- <div style="position:relative;" class="d-flex justify-center">
        <div style="height:50px;width:50px;background:orangered;border-radius:50%;opacity: 0.5;"></div>
            <span class="white--text font-weight-bold" style="position:absolute;top:17px;font-size:12px;">{{ value }}</span>
        </div> -->
        <div style="position:relative;" class="d-flex justify-center">
        <div :style="resultContainer" style="opacity: 0.5;" class="text-wrap"></div>
            <div
                :style="resultText"
                :class="textResultClass"
                style="position:absolute;"
            >
                {{ value }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        height: {
            type: Number,
            default: 50
        },
        width: {
            type: Number,
            default: 50
        },
        bgColor: {
            type: String,
            default: 'transparent'
        },
        square: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number],
            default: null
        },
        fontSize: {
            type: Number,
            default: 12
        },
        textResultClass: {
            type: String,
            default: null
        }
    },
    computed: {
        resultContainer() {
            return {
                height: `${this.height}px`,
                width: `${this.width}px`,
                background: this.bgColor,
                borderRadius: this.square ? 0 : '50%'
            }
        },
        resultText() {
            return {
                top: `${((this.height - this.fontSize) / 2) - 4}px`, // to align middle
                fontSize: `${this.fontSize}px`
            }
        }
    }
}
</script>