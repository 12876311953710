<template>
	<div>
		<div style="position:relative;" class="d-flex align-center" ref="circleValueRef" :key="componentKey">
			<div :style="outerBorder" style="border-radius:50%;">
				<div :style="innerBorder" style="border-radius:50%;">
					<div :style="resultContainer" style="border-radius:50%;" class="px-2"></div>
				</div>
			</div>
			<div
				:class="resultClass"
				style="position:absolute;"
				:style="{
					marginTop: '10px',
					width: `${this.componentSize}px`
				}"
			>
				<template v-if="bodySlots">
					<slot name="body"></slot>
				</template>
				<template v-else>
					<slot v-if="valueSlots" name="value"></slot>
					<div
						v-else
						class="d-flex align-center justify-center"
						:style="{ height: `auto` }"
						:class="valueClass"
					>
						{{ value }}
					</div>
					<slot v-if="labelSlots" name="label"></slot>
					<div
						v-else
						class="text-wrap d-flex align-center justify-center text-center"
						style="line-height: 1;"
						:style="{
							height: `${this.componentSize / 2}px`,
							width: `${this.componentSize}px`
						}"
						:class="labelClass"
					>
						{{ label }}
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		size: {
			type: Number,
			default: 100
		},
		bgColor: {
			type: String,
			default: 'transparent'
		},
		textResultClass: {
			type: String,
			default: null
		},
		outerBorderStyle: {
			type: String,
			// default: 'solid black 2px'
			default: null
		},
		innerBorderStyle: {
			type: String,
			// default: 'solid white 2px'
			default: null
		},
		label: {
			type: String,
			default: null
		},
		value: {
			type: [String, Number],
			default: null
		},
		resultClass: {
			type: String,
			default: null
			// white--text font-weight-bold text-center text-subtitle-1 px-1
		},
		valueClass: {
			type: String,
			default: 'font-weight-bold'
		},
		labelClass: {
			type: String,
			default: 'text-caption'
		},
		labelSlots: {
			type: Boolean,
			default: false
		},
		valueSlots: {
			type: Boolean,
			default: false
		},
		bodySlots: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		componentKey: 0,
		size2: 0
	}),
	computed: {
		resultContainer() {
			return {
				height: `${this.size}px`,
				width: `${this.size}px`,
				background: this.bgColor
			}
		},
		innerBorder() {
			return {
				border: this.innerBorderStyle ?? `solid white 2px`
			}
		},
		outerBorder() {
			return {
				border: this.outerBorderStyle ?? `solid ${this.bgColor} 4px`
			}
		},
		componentSize() {
			const key = this.componentKey

			let component = this?.$refs?.circleValueRef ?? null
			let height = component?.offsetHeight ?? this.size

			return height
		}
	},
	mounted() {
		this.componentKey++
	}
}
</script>