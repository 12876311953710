import { ADDED_TO_CHART_SMALL_SCORING, ADDED_TO_CHART_LARGE_SCORING } from './chartConfig'

export const respondentTrendOptions = {
	plugins: null,
	type: "bar",
	data: {
		datasets: [],
		labels: [],
	},
	options: {
		responsive: true,
		maintainAspectRatio: true,
		// aspectRatio: 1,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
				}
			}]
		},
		plugins: {
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: function (value, context) {
					if (context.dataset.label == "Ratio") {
						let percentage = parseFloat(context.dataset.ratio_data[context.dataIndex]);
						return percentage.toFixed(2) + '%';
					} else {
						return null;
					}
				},
			},
		},
	},
}

const setupRespondentTrend = ({ data =  [] }) => {
	let periods = data
	const actualTargetData = {
		targetData: {
			label: "Ratio",
			data: [],
			ratio_data: [],
			type: "line",
			backgroundColor: "red",
			borderColor: "#D8392B",
			fill: false,
			order: 1,
			pointStyle: "rectRot",
			radius: 5,
			lineTension: 0,
			datalabels: {
				labels: {
					title: null,
				},
			},
		},
		actualData: {
			label: "Intake",
			data: [],
			backgroundColor: "#4CAEE3",
			order: 2,
		},
	};

	let months = []
	var tempHighestScore = 0  //used for the max value of the chart

	periods.forEach((item) => {
		var targetData = (parseFloat(item.respondent_ratio) / 100) * parseInt(item.respondents_count);
		
		var targetRatioData = parseFloat(item.respondent_ratio);
		tempHighestScore = tempHighestScore >= parseInt(item.respondents_count) ? tempHighestScore : parseInt(item.respondents_count);

		actualTargetData.targetData.data.push(targetData);
		actualTargetData.targetData.ratio_data.push(targetRatioData);
		actualTargetData.actualData.data.push(item.respondents_count);
		months.push(item.month);
	});

	tempHighestScore = Number(tempHighestScore)

	return {
		datasets: [actualTargetData.targetData, actualTargetData.actualData],
		labels: months,
		maxScore: tempHighestScore
	}
}

export const setRespondentTrendOptions = ({ plugins = null, datasets = [], labels = [], max = null }) => {

	respondentTrendOptions.plugins = plugins
	respondentTrendOptions.data.datasets = datasets
	respondentTrendOptions.data.labels = labels
	respondentTrendOptions.options.scales.yAxes[0].ticks.max = max

	return respondentTrendOptions;
}

export const setupRespondentTrendChart = ({ data = [], plugins = null }) => {
	let { datasets, labels, maxScore } = setupRespondentTrend({ data });
	const newMaxScore =  Math.ceil(maxScore)

	return setRespondentTrendOptions({
		plugins,
		datasets,
		labels,
		max: newMaxScore <= 0 ? 1 : newMaxScore
	})
}