import { ADDED_TO_CHART_SMALL_SCORING, ADDED_TO_CHART_LARGE_SCORING } from './chartConfig'

export const factorScoreOptions = {
	plugins: null,
	type: "bar",
	data: {
		datasets: [],
		labels: [],
	},
	options: {
		responsive: true,
		maintainAspectRatio: true,
		// aspectRatio: 1,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
				}
			}]
		},
		plugins: {
			datalabels: {
				anchor: "end",
				align: "end",
				formatter: function (value, context) {

					if (context.dataset.label == "Actual") {
						return Math.round(value * 100) / 100;
					} else {
						return null;
					}
				},
			},
		},
	},
};

export const setupFactorScore = ({ data = [] }) => {
	const actualTargetData = {
		targetData: {
			label: "Target",
			data: [],
			type: "line",
			backgroundColor: "red",
			borderColor: "#D8392B",
			fill: false,
			order: 1,
			pointStyle: "rectRot",
			radius: 5,
			lineTension: 0,
		},
		actualData: {
			label: "Actual",
			data: [],
			backgroundColor: "#4CAEE3",
			order: 2,
		},
	};

	let factorScores = []

	let tempHighestScore = 0  //used for the max value of the chart

	data.forEach(item => {
		const targetData = item.dealer_target_score || item.default_target_score

		let higherScore = item.score >= targetData ? item.score : targetData
		tempHighestScore = tempHighestScore >= higherScore ? tempHighestScore : higherScore

		factorScores.push(item.factor_title)
		actualTargetData.targetData.data.push(targetData);
		actualTargetData.actualData.data.push(item.score);
	})

	tempHighestScore = Number(tempHighestScore)

	return {
		datasets: [actualTargetData.targetData, actualTargetData.actualData],
		labels: factorScores,
		maxScore: tempHighestScore
	}
}

export const setFactorScoreOptions = ({ plugins = null, datasets = [], labels = [], max = null }) => {
	factorScoreOptions.plugins = plugins
	factorScoreOptions.data.datasets = datasets
	factorScoreOptions.data.labels = labels
	factorScoreOptions.options.scales.yAxes[0].ticks.max = max

	return factorScoreOptions;
}

export const setupFactorScoreChart = ({ data = [], plugins = null }) => {
	let { datasets, labels, maxScore } = setupFactorScore({ data });
	const newMaxScore = Math.ceil((maxScore + (maxScore * ADDED_TO_CHART_SMALL_SCORING)) / ADDED_TO_CHART_LARGE_SCORING) * ADDED_TO_CHART_LARGE_SCORING
	return setFactorScoreOptions({
		plugins,
		datasets,
		labels,
		max: newMaxScore <= 0 ? 1 : newMaxScore
	})
}
