<template>
  <section style="position: relative">
    <div>
      <div style="position: relative">
        <div
          style="height: 400px; width: 300px; background: white"
          class="d-flex align-center"
        >
          <img :src="phMap" alt="" height="auto" width="100%" />
        </div>
        <div>
          <template v-for="(item, key) in formattedItems">
            <div
              :style="{
                top: `${item.coordinateY}px`,
                left: `${item.coordinateX}px`,
              }"
              style="position: absolute"
              :key="key"
            >
              <map-survey-result
                :bg-color="item.bgColor"
                :value="item.value"
                :text-result-class="item.textResultClass"
              ></map-survey-result>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="showLegends" style="position: absolute; top: 0; right: 0">
      <div class="font-weight-bold">Legends:</div>
      <div>
        <template v-for="(item, key) in formattedItems">
          <div :key="key" class="d-flex align-center">
            <span
              style="padding: 6px; border-radius: 50%;"
              :style="{ background: item.bgColor }"
              class="mr-2"
            ></span>
            <small>
              {{ item.name }}
            </small>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import philippineMapImg from "@/assets/reports/philippine_map_black.png";
import philippineMapBlueImg from "@/assets/reports/philippine_map_blue.png";
import MapSurveyResult from "../partials/MapSurveyResult.vue";

export default {
  components: {
    MapSurveyResult,
  },
  props: {
    mapColor: {
      type: String,
      default: "black",
      validator: (value) => {
        return ["black", "blue"].includes(value);
      },
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    showLegends: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({}),
  computed: {
    phMap() {
      if (this.mapColor === "blue") {
        return philippineMapBlueImg;
      }

      return philippineMapImg;
    },
    formattedItems() {
      if (this.items.length) {
        const newItems = [];

        this.items.forEach((item) => {
          newItems.push({
            value: item.value ?? 0,
            name: item.name ?? "",
            coordinateX: item.coordinateX ?? 0,
            coordinateY: item.coordinateY ?? 0,
            bgColor: item.bgColor ?? "transparent",
            textResultClass:
              item.textResultClass ?? "white--text font-weight-bold",
          });
        });

        return newItems;
      }

      return this.items;
    },
  },
};
</script>
